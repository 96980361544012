<template lang="pug">
  #appRoot
    template(v-if='!$route.meta.public')
      v-app
        AppDrawer.app--drawer(v-if='!$route.meta.public && showDrawer && !loading')
        AppToolbar.app--toolbar(v-if='!$route.meta.public && !loading')
        BottomNavigation(v-if='!$route.meta.public && !showDrawer && !loading')
        v-content(v-if="!loading")
          v-app-bar(color="#ff5722" dense v-if="showFixedText && showFixedText.length > 0")
            v-spacer
            span.white--text {{showFixedText}}
            //-span.white--text Visualizando como
              strong  {{user.me.name}}
            v-spacer
          v-snackbar( v-model="snackbarEnable" :timeout="0" top right) {{ snackbartext }}
            v-btn( color="pink" text @click="closeSnackbar" ) Fechar
          keep-alive
            router-view(v-show='!loading')
        v-container(v-else fluid fill-height)
          v-row(justify='center')
            v-scroll-y-transition(hide-on-leave)
              v-col(cols='auto')
                v-row
                  v-col.text-center
                    vue-loaders(name="ball-triangle-path" color="rgba(0,0,0,.87)" scale="1")
                v-row.mt-2
                  v-col.text-center
                    div.headline.font-weight-light {{ loaderText }}
    template(v-else)
      v-scroll-y-transition(hide-on-leave)
        router-view(v-show='!loading')
      v-app(v-if='loading')
        v-content
          v-container(fluid fill-height )
            v-row(justify='center')
              v-col(cols='auto')
                v-row
                  v-col.text-center
                    vue-loaders(name="ball-triangle-path" color="rgba(0,0,0,.87)" scale="1")
                v-row.mt-2
                  v-col.text-center
                    div.headline.font-weight-light {{ loaderText }}
</template>

<script>
import AppDrawer from '@/components/AppDrawer'
import AppToolbar from '@/components/AppToolbar'
import BottomNavigation from '@/components/BottomNavigation'
import Config from './api/api.config'
import MenuApi from './api/menu'
import { mapState } from 'vuex'
import organization from './api/organization'
import notifications from './notifications'
import momentConfig from './plugins/moment'
import Vue from 'vue'
import VueMoment from 'vue-moment'

export default {
  name: 'App',
  components: {
    AppDrawer,
    AppToolbar,
    BottomNavigation
  },
  data () {
    return {
      loading: true,
      loaderText: '',
      loaderIndex: 0,
      institution: {
        infos: {
          fixedMessage: null
        }
      },
      loadedText: 'Carregando o aplicativo, por favor aguarde...'
    }
  },
  methods: {
    closeSnackbar () {
      this.$store.dispatch('closeSnackbar')
    }
  },
  computed: {
    ...mapState(['user', 'auth']),
    ...mapState('app', ['snackbarEnable']),
    // snackbarenabled () {
    //   if (this.$store) {
    //     return this.$store.state.app.snackbarEnable
    //   } else {
    //     return false
    //   }
    // },
    showFixedText () {
      const searchParams = new URLSearchParams(window.location.search)
      const boolTemp = searchParams.has('temp_token')
      if (boolTemp) {
        return `Visualizando como: ${this.user.me.name}`
      } else if (this.institution.infos.fixedText) {
        return this.institution.infos.fixedText
      } else {
        return ''
      }
    },
    snackbartext () {
      if (this.$store) {
        return this.$store.state.app.snackbarText
      } else {
        return ''
      }
    },
    showDrawer () {
      return this.$store.getters['app/showingDrawer']
    }
  },
  async created () {
    // Set moment
    const moment = await momentConfig.getMoment()
    Vue.use(VueMoment, { moment })

    console.log('TEST VUE MOMENT REAL TIME', this.$moment().format('YYYY-mm-DD HH:mm:ss.SSSSZ'))

    if (this.$route.path.indexOf('dash') === '/dash') {
      this.loading = false
    } else {
      var intervalRefresh = setInterval(() => {
        if (this.loaderIndex < this.loadedText.length) {
          this.loaderText += this.loadedText[this.loaderIndex++]
        } else {
          clearInterval(intervalRefresh)
        }
      }, 30)

      const institution = this.$route.params.institution || process.env.VUE_APP_CORDOVA
      console.log('INSTITUTION', institution)

      if (institution) {
        // Config institution
        Config.setup(institution)
        this.$store.commit('auth/setInstitution', institution)

        // Config theme
        const data = await organization.infos()
        this.institution = data
        this.$store.commit('organization/set', data)
        const theme = data.theme
        this.$vuetify.theme.themes.light = theme.themes.light
        this.$vuetify.theme.themes.dark = theme.themes.dark
      }

      if (this.$store.getters['auth/authenticated']) {
        // Set user environment
        await MenuApi.get()
        this.$store.dispatch('user/getMe')

        // Set notifications
        notifications.setFCM()
      }

      this.loading = false
    }
  }
}
</script>

<style lang="sass" scoped>
  // .setting-fab
  //   top:50%!important
  //   right:0
  //   border-radius:0
  // .page-wrapper
  //   min-height:calc(100vh - 64px - 50px - 81px )

</style>
